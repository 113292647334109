import { Account } from '@ui-resources-angular';
import {
  TriggerConfig,
  ActionConfig,
  UIParam,
  UIParamFormType
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  mapSelectOptions,
  getParamByName,
  minShouldMatchParamFactory,
  IngredientConfig,
  IngredientType
} from './util';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../common/services/api';
import { AppletTriggerParam, AppletActionParam } from '../applets/applet';
import { Webhook } from '../../../../../common/services/webhook-model/webhook-model';

const triggers: TriggerConfig[] = [
  {
    api: {
      name: 'from account'
    },
    isAccountTrigger: true,
    getLabel(params: AppletTriggerParam[]) {
      const accounts: Account[] = getParamByName(params, 'accounts').value;
      return (
        'Is on account ' +
        accounts
          .map(
            (account) => `${account.displayName} (${account.account_type_name})`
          )
          .join(' or ')
      );
    }
  },
  {
    api: {
      name: 'is public'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_IS_PUBLIC_TITLE',
      description: 'CONVERSATION_TRIGGER_IS_PUBLIC_DESCRIPTION'
    },
    iconClass: 'ssi ssi-public-correct',
    getLabel() {
      return `Is public`;
    }
  },
  {
    api: {
      name: 'is private'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_IS_PRIVATE_TITLE',
      description: 'CONVERSATION_TRIGGER_IS_PRIVATE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-private-correct',
    getLabel() {
      return `Is private`;
    }
  },
  {
    api: {
      name: 'sentiment'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_SENTIMENT_TITLE',
      description: 'CONVERSATION_TRIGGER_SENTIMENT_DESCRIPTION'
    },
    iconClass: 'ssi ssi-semi-positive',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 220
    },
    getLabel(params: AppletTriggerParam[]) {
      const sentimentKeys = getParamByName(
        params,
        'sentiments'
      ) as AppletTriggerParam;
      const sentiment: string[] = mapSelectOptions(
        sentimentKeys,
        sentimentKeys.param.form.sentiment.options
      );
      return `Sentiment is ${sentiment.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'language'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_LANGUAGE_TITLE',
      description: 'CONVERSATION_TRIGGER_LANGUAGE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-language',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const languageKeys = getParamByName(
        params,
        'languages'
      ) as AppletTriggerParam;
      const langs: string[] = mapSelectOptions(
        languageKeys,
        languageKeys.param.form.select.options
      );
      return `Language is ${langs.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'queries match content'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_CONTAINING_WORD_TITLE',
      description: 'CONVERSATION_TRIGGER_CONTAINING_WORD_DESCRIPTION'
    },
    iconClass: 'ssi ssi-document-help',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletTriggerParam[]) {
      const mode: number = getParamByName(params, 'mode').value;
      const queries: string[] = getParamByName(params, 'queries').value;
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `${
        mode === 0 ? 'Contains the text' : 'Matches word(s)'
      } ${queries.map((query) => `"${query}"`).join(` ${operand} `)}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory(),
      mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.Hidden,
            defaultValue: 0,
            showAsCheckboxInput: true
          }
        };
      }
    }
  },
  {
    api: {
      name: 'created in office hours'
    },
    translationIds: {
      title: 'CONVERSATION_TRIGGER_CREATED_IN_OFFICE_HOURS_TITLE',
      description: 'CONVERSATION_TRIGGER_CREATED_IN_OFFICE_HOURS_DESCRIPTION'
    },
    iconClass: 'ssi ssi-clock',
    getLabel(params: AppletTriggerParam[]) {
      const inHours: boolean = getParamByName(params, 'in_hours').value;
      return inHours
        ? 'Created within office hours'
        : 'Created outside of office hours';
    },
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 270
    },
    paramOverrides: {
      in_hours() {
        return {
          form: {
            type: UIParamFormType.SelectSingle,
            select: {
              options: [
                {
                  value: true,
                  label: 'Is within office hours',
                  trackBy: 0
                },
                {
                  value: false,
                  label: 'Is outside of office hours',
                  trackBy: 1
                },
                {
                  value: undefined,
                  label: 'All hours',
                  trackBy: 2
                }
              ]
            }
          }
        };
      }
    }
  }
];

const actions: ActionConfig[] = [
  {
    api: {
      name: 'assign conversation to user'
    },
    translationIds: {
      title: 'CONVERSATION_ACTION_ASSIGN_CONVERSATION_TO_USER_TITLE',
      description: 'CONVERSATION_ACTION_ASSIGN_CONVERSATION_TO_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-oval-user-icon',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const user: Colleague = getParamByName(params, 'user_id').value;
      return `Assign conversation to ${user && user.fullName}`;
    }
  },
  {
    api: {
      name: 'assign conversation to team'
    },
    translationIds: {
      title: 'CONVERSATION_ACTION_ASSIGN_CONVERSATION_TO_TEAM_TITLE',
      description: 'CONVERSATION_ACTION_ASSIGN_CONVERSATION_TO_TEAM_DESCRIPTION'
    },
    iconClass: 'ssi ssi-assign-team',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const team: Team = getParamByName(params, 'team_id').value;
      return `Assign conversation to ${team && team.name}`;
    }
  },
  {
    api: {
      name: 'send webhook message'
    },
    translationIds: {
      title: 'CONVERSATION_ACTION_SEND_WEBHOOK_MESSAGE_TITLE',
      description: 'CONVERSATION_ACTION_SEND_WEBHOOK_MESSAGE_DESCRIPTION'
    },
    iconClass: 'fa fa-plug',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const webhook: Webhook = getParamByName(params, 'webhook_id').value;
      return `Send a webhook notification to "${webhook && webhook.name}"`;
    }
  }
];

const ingredients: IngredientConfig[] = [
  {
    type: IngredientType.Template,
    api: {
      name: 'account.name'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_ACCOUNT_NAME_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'account_type.name'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_ACCOUNT_TYPE_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.content'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_INTERACTION_CONTENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'sentiment'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_SENTIMENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'visibility'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_VISIBILITY_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.created_at'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_CREATED_AT_LABEL'
    }
  }
];

const service: ServiceMapping = {
  id: 'conversation.added_incoming_message',
  brand: {
    iconClass: 'ssi ssi-conversations'
  },
  triggers,
  actions,
  ingredients,
  translationIds: {
    title: 'CONVERSATION',
    triggers: 'CONVERSATION_TRIGGERS',
    actions: 'CONVERSATION_ACTIONS'
  }
};

export default service;
